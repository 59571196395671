import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './components/App/App.js'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
      <App /> 
  </BrowserRouter>,
  document.getElementById('root')
)

reportWebVitals();
