import React from 'react'
import './NotFoundPage.css'

export default function NotFoundPage() {
    return (
        <div className="not-found-page">
            <div className="center">
                <h1 className="missing-page-title">Whoops looks like this page doesn't exist!</h1>
            </div>
            
        </div>
    )
}